import React from "react"
import Layout from "../components/Layout"
import { Link } from "gatsby"
import smallbusinesshero from "../images/smallBusiness_hero.png"
import smallbusinessbanner from "../images/smallBusiness-banner.png"
import solutionsearch from "../images/solution-search.png"
import solutioninteract from "../images/solution-interact.png"
import solutionrepeat from "../images/solution-repeat.png"
import solutionreferrals from "../images/solution-referrals.png"
import solutionanalytics from "../images/solution-analytics.png"
import Finalctablock from "../components/Finalctablock"

const enterprise = () => {
  return (
    <Layout>
      <div className="smb-grad"></div>
      <div className="container">
        <section className="smb-hero">
          <div className="smb-herotxt">
            <h1>Dominating with local marketing at scale</h1>
            <p>
              Increase revenue and operations at scale. Interact with customers
              at every touchpoint be it 10 or 10,000 locations.
            </p>
            <a href="">
              <button className="btn btn-main btn-hero">BOOK DEMO</button>
            </a>
          </div>

          <img src={smallbusinesshero} className="smb-heroimg" alt="" />
        </section>
      </div>

      <div className="container">
        <section className="smallBusiness-main-section">
          <h2>
            The location marketing platform to win more leads, earn repeat
            customers and be the best business.
          </h2>

          <img src={smallbusinessbanner} alt="" />
        </section>
      </div>

      <div className="container feature-stripe-cont">
        <div className="solutionstripefeaturetop"></div>
        <section className="features-grid smb-feature-one">
          <img src={solutionsearch} alt="" />
          <div className="featurestxt">
            <h3>Get found on sites that matter</h3>
            <p>
              It all starts with a search - show up on top of Google, Facebook,
              and industry sites with real customer reviews. 30 seconds is all
              it takes for your customers to leave reviews on sites customers
              trust.
            </p>
            <Link to="/">MORE ON REVIEWS</Link>
          </div>
        </section>
        <div className="solutionstripefeaturebottom"></div>
      </div>

      <div className="container feature-stripe-cont">
        <section className="features-grid smb-feature-two">
          <div className="featurestxt">
            <h3>
              Interact with leads <br></br> and customers the way they prefer
            </h3>
            <p>
              Todays customers demand convenience and instant answers. Whether
              they start on Google, Facebook, or your website, every message
              comes to the same inbox so you never miss a lead. Answer
              questions, book appointments, close the deal, and provide support
              - all without having to pick up the phone.
            </p>
            <Link to="/">MORE ON INTERACTIONS</Link>
          </div>
          <img src={solutioninteract} alt="" />
        </section>
      </div>

      <div className="container feature-stripe-cont">
        <div className="solutionstripefeaturetop"></div>
        <section className="features-grid smb-feature-three">
          <img src={solutionrepeat} alt="" />
          <div className="featurestxt">
            <h3>Earn repeat customers</h3>
            <p>
              Provide your team the ability to create seamless and unmatched
              customer experiences from start to finish. No call transfers. No
              hold music. Just frictionless messaging. The next time your
              customers need a business like yours, it won’t start with a
              search. They will already had a great experience and your number
              will be saved in their phone.
            </p>
            <Link to="/">MORE ON INTERACTIONS</Link>
          </div>
        </section>
        <div className="solutionstripefeaturebottom"></div>
      </div>

      <div className="container feature-stripe-cont">
        <section className="features-grid smb-feature-four">
          <div className="featurestxt">
            <h3>Use your existing customers to accelerate word of mouth</h3>
            <p>
              After collecting reviews from your customers, let your customers
              refer your business to their friends and family. Track all
              reviews, and referrals in one place.
            </p>
            <Link to="/">MORE ON REFERRALS</Link>
          </div>
          <img src={solutionreferrals} alt="" />
        </section>
      </div>

      <div className="container feature-stripe-cont">
        <div className="solutionstripefeaturetop"></div>
        <section className="features-grid smb-feature-five">
          <img src={solutionanalytics} alt="" />
          <div className="featurestxt">
            <h3>Monitor business performance</h3>
            <p>
              With analytics see how your business is performing by comparing
              how your referrals, reviews and ratings vary from month to month.
            </p>
          </div>
        </section>
      </div>

      <Finalctablock />
    </Layout>
  )
}

export default enterprise
